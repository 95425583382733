import { Button, Container, Flex, MantineProvider, Text } from "@mantine/core";
import {
  IconBallBasketball,
  IconBike,
  IconBlockquote,
  IconBrandDiscord,
  IconBrandGithub,
  IconBrandGmail,
  IconBrandGooglePhotos,
  IconBrandReddit,
  IconBrandSoundcloud,
  IconBrandStrava,
  IconBrandTwitter,
  IconBrandYcombinator,
  IconBrandYoutube,
  IconCamera,
  IconCloudDownload,
  IconFiles,
  IconFlask,
  IconHomeDollar,
  IconMagnet,
  IconMusicDown,
  IconNotes,
  IconRss,
  IconSun,
  IconSunWind,
} from "@tabler/icons-react";
import { render } from "preact";
import { ForwardRefExoticComponent } from "preact/compat";
import "./app.css";

const App = () => {
  const links: {
    name: string;
    url: string;
    icon: ForwardRefExoticComponent<any>;
  }[] = [
    {
      name: "Twitter",
      url: "https://www.twitter.com",
      icon: IconBrandTwitter,
    },
    {
      name: "Reddit",
      url: "https://www.reddit.com",
      icon: IconBrandReddit,
    },
    {
      name: "Strava",
      url: "https://www.strava.com",
      icon: IconBrandStrava,
    },
    {
      name: "Hacker News",
      url: "https://news.ycombinator.com",
      icon: IconBrandYcombinator,
    },
    {
      name: "NZBs.in",
      url: "https://nzbs.in",
      icon: IconCloudDownload,
    },
    {
      name: "Soundcloud",
      url: "https://soundcloud.com",
      icon: IconBrandSoundcloud,
    },
    {
      name: "Discord",
      url: "https://discord.com/channels/696015284250607717/696015284775157802",
      icon: IconBrandDiscord,
    },
    {
      name: "Github",
      url: "https://github.com",
      icon: IconBrandGithub,
    },
    {
      name: "Inbox",
      url: "https://mail.google.com",
      icon: IconBrandGmail,
    },
    {
      name: "Inbox #1",
      url: "https://mail.google.com/mail/u/1/#inbox",
      icon: IconBrandGmail,
    },
    {
      name: "RSS",
      url: "https://rss.shagr.at",
      icon: IconRss,
    },
    {
      name: "Nantes",
      url: "https://meteofrance.com/previsions-meteo-france/nantes/44000",
      icon: IconSunWind,
    },
    {
      name: "Jard",
      url: "https://meteofrance.com/previsions-meteo-france/jard-sur-mer/85520",
      icon: IconSun,
    },
    {
      name: "Immo",
      url: "https://www.leboncoin.fr/recherche?category=9&locations=Thouar%C3%A9-sur-Loire_44470__47.26698_-1.44065_4342%2C44300%2C44240%2COrvault_44700%2CCarquefou_44470%2CSainte-Luce-sur-Loire_44980&real_estate_type=1&price=300000-550000",
      icon: IconHomeDollar,
    },
    {
      name: "Dealabs",
      url: "https://www.dealabs.com",
      icon: IconFlask,
    },
    {
      name: "shagr.at",
      url: "https://shagr.at",
      icon: IconBlockquote,
    },
    {
      name: "Photos",
      url: "https://photos.google.com/",
      icon: IconBrandGooglePhotos,
    },
    {
      name: "Yt-DLP",
      url: "https://ydl.shagr.at",
      icon: IconBrandYoutube,
    },
    {
      name: "Files",
      url: "https://files.shagr.at",
      icon: IconFiles,
    },
    {
      name: "Soulseek",
      url: "https://soulseek.shagr.at",
      icon: IconMusicDown,
    },
    {
      name: "Notes",
      url: "https://notes.shagr.at",
      icon: IconNotes,
    },
    {
      name: "Torrents",
      url: "https://torrent.shagr.at",
      icon: IconMagnet,
    },
    {
      name: "SABNzbd",
      url: "https://sabnzbd.shagr.at",
      icon: IconCloudDownload,
    },
    {
      name: "Gallery",
      url: "https://photos.shagr.at",
      icon: IconCamera,
    },
    {
      name: "NBA",
      url: "https://www.google.com/search?q=nba&oq=nba",
      icon: IconBallBasketball,
    },
    {
      name: "PCS",
      url: "https://www.procyclingstats.com",
      icon: IconBike,
    },
  ];

  return (
    <MantineProvider defaultColorScheme="dark">
      <Container size="md" mt={5}>
        <Flex wrap="wrap" justify="center">
          {links.map((link, index) => (
            <Button
              key={"id-" + index}
              color="indigo.3"
              h={78}
              justify="center"
              variant="light"
              radius="lg"
              m={3}
              w={78}
              component="a"
              href={link.url}
              styles={{
                root: {
                  padding: "0px",
                },
                inner: {
                  alignItems: "start",
                },
                label: {
                  alignItems: "start",
                  paddingTop: "10px",
                },
              }}
            >
              <Flex w="100%" direction="column" align="center">
                <link.icon size={36} stroke={1} />
                <div
                  style={{
                    wordBreak: "break-all",
                  }}
                >
                  <Text mt={5} c="indigo.1" size="xs" fw={600}>
                    {link.name}
                  </Text>
                </div>
              </Flex>
            </Button>
          ))}
        </Flex>
      </Container>
    </MantineProvider>
  );
};

// Mount component
render(<App />, document.body);
